/* IMPORT */
import AbstractActivationElementwise from './abstract_activation_elementwise.js';
/* MAIN */
class Relu extends AbstractActivationElementwise {
    /* API */
    activationForward(x) {
        return (x <= 0) ? 0 : x;
    }
    activationBackward(x, dx) {
        return (x <= 0) ? 0 : dx;
    }
}
/* EXPORT */
export default Relu;
