/* IMPORT */
import AbstractActivationElementwise from './abstract_activation_elementwise.js';
/* MAIN */
class Sigmoid extends AbstractActivationElementwise {
    /* API */
    activationForward(x) {
        return 1 / (1 + Math.exp(-x));
    }
    activationBackward(x, dx) {
        return x * (1 - x) * dx;
    }
}
/* EXPORT */
export default Sigmoid;
