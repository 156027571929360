export default {
  get applicationName(): null {
    return null;
  },
  get bundleId(): null {
    return null;
  },
  get nativeApplicationVersion(): null {
    return null;
  },
  get nativeBuildVersion(): null {
    return null;
  },
  get androidId(): null {
    return null;
  },
  async getInstallationTimeAsync(): Promise<null> {
    return null;
  },
};
