/* IMPRT */
import Abstract from './abstract.js';
import AbstractActivation from './abstract_activation.js';
import AbstractHidden from './abstract_hidden.js';
import AbstractInput from './abstract_input.js';
import AbstractOutput from './abstract_output.js';
import Conv from './conv.js';
import Dense from './dense.js';
import Dropout from './dropout.js';
import Input from './input.js';
import LeakyRelu from './leakyrelu.js';
import Maxout from './maxout.js';
import Pool from './pool.js';
import Regression from './regression.js';
import Relu from './relu.js';
import Sigmoid from './sigmoid.js';
import Softmax from './softmax.js';
import Tanh from './tanh.js';
/* MAIN */
const Layers = {
    /* ABSTRACT */
    Abstract,
    AbstractActivation,
    AbstractHidden,
    AbstractInput,
    AbstractOutput,
    /* INPUT */
    Input,
    /* HIDDEN */
    Conv,
    Dense,
    Dropout,
    Pool,
    /* ACTIVATION */
    LeakyRelu,
    Maxout,
    Relu,
    Sigmoid,
    Tanh,
    /* OUTPUT */
    Regression,
    Softmax
};
/* EXPORT */
export default Layers;
