/* IMPORT */
import AbstractActivationElementwise from './abstract_activation_elementwise.js';
/* MAIN */
class Tanh extends AbstractActivationElementwise {
    /* API */
    activationForward(x) {
        return Math.tanh(x);
    }
    activationBackward(x, dx) {
        return (1 - (x ** 2)) * dx;
    }
}
/* EXPORT */
export default Tanh;
