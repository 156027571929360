/* IMPORT */
import AbstractInput from './abstract_input.js';
/* MAIN */
class Input extends AbstractInput {
    /* CONSTRUCTOR */
    constructor(options, prev) {
        super(options, prev);
        this.osx = options.sx;
        this.osy = options.sy;
        this.osz = options.sz;
    }
    /* API */
    forward(input, isTraining) {
        this.it = input;
        this.ot = input;
        return this.ot;
    }
}
/* EXPORT */
export default Input;
