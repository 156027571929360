/* IMPORT */
import Layers from './layers/index.js';
import Abstract from './layers/abstract.js';
import AbstractInput from './layers/abstract_input.js';
import AbstractHidden from './layers/abstract_hidden.js';
import AbstractOutput from './layers/abstract_output.js';
/* MAIN */
class NeuralNetwork {
    /* CONSTRUCTOR */
    constructor(options) {
        this.layers = this.resolve(options.layers); //TSC
        this.options = options;
        if (this.layers.length < 2)
            throw new Error('At least one input layer and one output layer are required');
        if (!this.layers.slice(0, 1).every(layer => layer instanceof AbstractInput))
            throw new Error('The first layer must be an input layer');
        if (!this.layers.slice(-1).every(layer => layer instanceof AbstractOutput))
            throw new Error('The last layer must be an output layer');
        if (!this.layers.slice(1, -1).every(layer => layer instanceof AbstractHidden))
            throw new Error('The layers between the first and the last must be hidden layers');
    }
    /* API */
    cost(input, output) {
        this.forward(input, false);
        return this.layers[this.layers.length - 1].backward(output);
    }
    forward(input, isTraining) {
        let result = this.layers[0].forward(input, isTraining);
        for (let i = 1, l = this.layers.length; i < l; i++) {
            result = this.layers[i].forward(result, isTraining);
        }
        return result;
    }
    backward(output) {
        const loss = this.layers[this.layers.length - 1].backward(output);
        for (let i = this.layers.length - 2; i >= 1; i--) {
            this.layers[i].backward(output);
        }
        return loss;
    }
    resolve(layers) {
        const resolveLayer = (layer, prev) => {
            if (layer instanceof Abstract)
                return layer;
            switch (layer.type) {
                /* INPUT */
                case 'input': return new Layers.Input(layer, prev);
                /* HIDDEN */
                case 'conv': return new Layers.Conv(layer, prev);
                case 'dense': return new Layers.Dense(layer, prev);
                case 'dropout': return new Layers.Dropout(layer, prev);
                case 'pool': return new Layers.Pool(layer, prev);
                /* ACTIVATION */
                case 'leakyrelu': return new Layers.LeakyRelu(layer, prev);
                case 'maxout': return new Layers.Maxout(layer, prev);
                case 'relu': return new Layers.Relu(layer, prev);
                case 'sigmoid': return new Layers.Sigmoid(layer, prev);
                case 'tanh': return new Layers.Tanh(layer, prev);
                /* OUTPUT */
                case 'regression': return new Layers.Regression(layer, prev);
                case 'softmax': return new Layers.Softmax(layer, prev);
                /* DEFAULT */
                default: throw new Error('Unknown layer type');
            }
        };
        let prev;
        return layers.map(layer => {
            return prev = resolveLayer(layer, prev);
        });
    }
    getAsOptions(precision = 'f32') {
        return {
            layers: this.layers.map(layer => layer.getAsOptions(precision))
        }; //TSC
    }
    getParamsAndGrads() {
        return this.layers.flatMap(layer => layer.getParamsAndGrads());
    }
}
/* EXPORT */
export default NeuralNetwork;
