/* IMPORT */
import AbstractActivationElementwise from './abstract_activation_elementwise.js';
/* MAIN */
class LeakyRelu extends AbstractActivationElementwise {
    /* API */
    activationForward(x) {
        return Math.max(.01 * x, x);
    }
    activationBackward(x, dx) {
        return (x <= 0) ? 0 : dx;
    }
}
/* EXPORT */
export default LeakyRelu;
