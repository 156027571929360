/* IMPORT */
import Buffer from '../buffer.js';
import AbstractActivation from './abstract_activation.js';
/* MAIN */
class AbstractActivationElementwise extends AbstractActivation {
    /* API */
    activationForward(x) {
        throw new Error('Unimplemented');
    }
    activationBackward(x, dx) {
        throw new Error('Unimplemented');
    }
    forward(input, isTraining) {
        this.it = input;
        const output = input.clone();
        for (let i = 0, l = input.length; i < l; i++) {
            output.w[i] = this.activationForward(output.w[i]);
        }
        this.ot = output;
        return this.ot;
    }
    backward() {
        const input = this.it;
        const output = this.ot;
        input.dw = new Buffer(input.length);
        for (let i = 0, l = input.length; i < l; i++) {
            input.dw[i] = this.activationBackward(output.w[i], output.dw[i]);
        }
    }
}
/* EXPORT */
export default AbstractActivationElementwise;
