/* IMPORT */
/* MAIN */
class Abstract {
    /* CONSTRUCTOR */
    constructor(options, prev) {
        this.options = options;
        this.isx = prev?.osx ?? -1;
        this.isy = prev?.osy ?? -1;
        this.isz = prev?.osz ?? -1;
        this.il = this.isx * this.isy * this.isz;
        this.osx = this.isx;
        this.osy = this.isy;
        this.osz = this.isz;
    }
    /* API */
    forward(input, isTraining) {
        throw new Error('Not implemented');
    }
    backward(output) {
        throw new Error('Not implemented');
    }
    getAsOptions(precision) {
        return this.options;
    }
    getParamsAndGrads() {
        return [];
    }
}
/* EXPORT */
export default Abstract;
