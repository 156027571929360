/* IMPORT */
import Buffer from './buffer.js';
import { isBuffer, isNumber, isUndefined, randn } from './utils.js';
/* MAIN */
//TODO: Generalize this to an arbitrary number of dimensions
class Tensor {
    /* CONSTRUCTOR */
    constructor(sx, sy, sz, value, dvalue) {
        this.sx = sx;
        this.sy = sy;
        this.sz = sz;
        this.length = sx * sy * sz;
        /* INITIALIZING WEIGHTS */
        if (isUndefined(value)) { // With normalized random values
            const scale = Math.sqrt(1 / this.length);
            const get = () => randn(0, scale);
            this.w = new Buffer(this.length).map(get);
        }
        else if (isNumber(value)) { // With a fixed value
            this.w = new Buffer(this.length);
            if (value !== 0) {
                this.w.fill(value);
            }
        }
        else if (isBuffer(value)) { // With an existing buffer
            this.w = value;
        }
        else { // With an existing array
            this.w = new Buffer(value);
        }
        /* INTIALIZING D-WEIGHTS */
        this.dw = dvalue || new Buffer(this.length);
    }
    /* WEIGHTS API */
    index(x, y, z) {
        return (((this.sx * y) + x) * this.sz) + z;
    }
    get(x, y, z) {
        return this.w[this.index(x, y, z)];
    }
    set(x, y, z, value) {
        return this.w[this.index(x, y, z)] = value;
    }
    add(x, y, z, value) {
        return this.w[this.index(x, y, z)] += value;
    }
    /* GRADIENT API */
    getGrad(x, y, z) {
        return this.dw[this.index(x, y, z)];
    }
    setGrad(x, y, z, value) {
        return this.dw[this.index(x, y, z)] = value;
    }
    addGrad(x, y, z, value) {
        return this.dw[this.index(x, y, z)] += value;
    }
    /* CLONE API */
    clone() {
        const clone = new Tensor(this.sx, this.sy, this.sz, this.w);
        clone.w = clone.w.slice();
        return clone;
    }
    cloneWithZeros() {
        return new Tensor(this.sx, this.sy, this.sz, 0);
    }
}
/* EXPORT */
export default Tensor;
