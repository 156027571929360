/* IMPORT */
import Buffer from '../buffer.js';
import AbstractOutput from './abstract_output.js';
/* MAIN */
class Regression extends AbstractOutput {
    /* CONSTRUCTOR */
    constructor(options, prev) {
        super(options, prev);
        this.osx = 1;
        this.osy = 1;
        this.osz = this.il;
    }
    /* API */
    forward(input, isTraining) {
        this.it = input;
        this.ot = input;
        return input;
    }
    backward(output) {
        const input = this.it;
        input.dw = new Buffer(input.length);
        let loss = 0;
        for (let i = 0, l = this.osz; i < l; i++) {
            let dy = input.w[i] - output[i];
            input.dw[i] = dy;
            loss += 0.5 * dy * dy;
        }
        return loss;
    }
}
/* EXPORT */
export default Regression;
