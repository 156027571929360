/* MAIN */
const isBuffer = (value) => {
    return (value instanceof Float32Array);
};
const isNumber = (value) => {
    return (typeof value === 'number');
};
const isString = (value) => {
    return (typeof value === 'string');
};
const isUndefined = (value) => {
    return (typeof value === 'undefined');
};
const randn = (mean, stdev) => {
    //URL: https://stackoverflow.com/a/36481059/1420197
    const u = 1 - Math.random();
    const v = Math.random();
    const z = Math.sqrt(-2 * Math.log(u)) * Math.cos(2 * Math.PI * v);
    const rand = (z * stdev + mean);
    return rand;
};
const range = (start, end) => {
    const length = end - start;
    return Array.from({ length }, (_, i) => i + start);
};
/* EXPORT */
export { isBuffer, isNumber, isString, isUndefined, randn, range };
